<template>
    <v-row justify="center"><v-col cols="auto">
            <v-card max-width="500" color="deep-purple-lighten-5">
                <v-form @submit.prevent="joinWaitlist" ref="form" :disabled="submitting">
                    <v-card-item>
                        <v-card-text v-if="!submitted">
                            <v-card-title class="text-center">Join the waitlist</v-card-title>
                            <v-text-field color="grey-lighten-4" :rules="[emailValidate]" variant="solo-filled" type="email"
                                v-model="email" :counter="80" label="Email" required></v-text-field>
                            <v-checkbox v-model="isBuddy" label="Buddy material" :hint="buddyHint" persistent-hint />
                            <v-checkbox v-model="isBeta" label="Early adopter" :hint="betaHint" persistent-hint />
                            <v-card-actions>
                                <v-spacer />
                                <v-btn type="submit" :loading="submitting" variant="elevated"
                                    color="light-green-darken-1">Join!</v-btn>
                            </v-card-actions>
                        </v-card-text>
                        <v-card-text v-else>
                            <p>Thank you for joining the waitlist. We are thrilled to have you here!</p>
                            <p>When the service will be ready to invite you - we will notify you via email.</p>
                            <hr v-if="isBuddy || isBeta" style="margin-bottom:1em" />
                            <p v-if="isBuddy">Buddies are crucial for us and for people they will support. Thank you for
                                being one.</p>
                            <p v-if="isBeta">As you are willing to be early addopter - you will receive notification earlier
                                than most.</p>
                        </v-card-text>
                    </v-card-item>
                </v-form>
            </v-card>
        </v-col></v-row>
</template>


<script lang="ts">
import { newTwirpAPI } from '@/api/api';
import type { RuntimeConfig } from 'nuxt/schema';
let runtimeConfig: RuntimeConfig;

export default {
    setup() {
        runtimeConfig = useRuntimeConfig();

        return {
            runtimeConfig,
        }
    },
    inject: ['scheduleToast'],
    data: () => ({
        email: '',
        isBuddy: false,
        isBeta: false,
        buddyHint: 'You can be a buddy for someone. Still, buddies can have buddies too!',
        betaHint: 'You are interested to start using this service as early as possible, even with some possible hick-ups. We may also contact you to get some feedback.',
        re: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        submitting: false,
        submitted: false,
    }),
    methods: {
        emailValidate(email: string): boolean | string {
            if (!email) {
                return "Email cannot be empty"
            }

            if (this.re.test(email)) {
                return true;
            }

            return "Email is not valid"
        },
        async joinWaitlist() {
            const { valid } = await this.$refs.form.validate();
            if (!valid) {
                return;
            }

            const api = newTwirpAPI((): string => { return "" }, runtimeConfig.public.apiURL);
            try {
                this.submitting = true;
                await api.joinWaitlist({
                    email: this.email,
                    isBuddy: this.isBuddy,
                    isBeta: this.isBeta,
                });
                this.submitted = true;
            } catch (e) {
                this.scheduleToast("Joining waitlist was unsuccessful: " + e.message, 'error')
            } finally {
                this.submitting = false;
            }
        }
    }
}
</script>

<style scoped>
.v-col {
    padding-top: 0;
}

/* .v-input__details {
    padding-top: 0;
    margin-top: 0;
} */
</style>