<template>
    <h1 class="text-center">Sometimes you just want to hear a friendly "Hello".</h1>
    <br>
    <v-row>
        <v-col>
            <v-sheet elevation="0">
                <v-card-title>Never alone</v-card-title>
                <v-card-text>
                    <p>
                        Our <span class="font-weight-bold">AI-powered buddy</span> called <span
                            class="font-italic">Eugene</span> is always there to have a great chat with you!
                    </p>
                    <p>
                        Tell about your day, share a story, crack a joke. <span class="font-italic">Eugene</span>
                        can also try to share about his day with you as well,
                        give it a shot!
                    </p>
                </v-card-text>
            </v-sheet>
        </v-col>
        <v-col>
            <v-sheet elevation="0">
                <v-card-title>Talk to other people</v-card-title>
                <v-card-text>
                    <p>
                        There are always somebody that would like to talk to you.
                    </p>
                    <p>
                        We all have bad days, but sometimes even simple "Hello" can change another person's day.
                    </p>
                </v-card-text>
            </v-sheet>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-sheet>
                <v-card-title>Find friends</v-card-title>
                <v-card-text>
                    <p>
                        This service will allow you to talk to other people.
                    </p>
                    <p>
                        Other people just like you.
                    </p>
                </v-card-text>
            </v-sheet>
        </v-col>
        <v-col>
            <v-sheet elevation="0">
                <v-card-title>Be anonymous</v-card-title>
                <v-card-text>
                    <p>
                        You don't have to choose, or find, somebody to talk to, service will match you with
                        other
                        users automatically.
                    </p>
                    <p>
                        This allows to find new people without sharing your personal information and doing the
                        work yourself.
                    </p>
                </v-card-text>
            </v-sheet>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            And most importantly..
            <v-sheet elevation="0">
                <v-card-title class="text-center">
                    <h2>Help others</h2>
                </v-card-title>
                <v-card-text>
                    <p>
                        Choose to be a buddy, which would mean that you will be matched to users that
                        want to have a talk buddy.
                    </p>
                    <p>
                        Talk to others, share how your day went, hear their stories.
                    </p>
                </v-card-text>
            </v-sheet>
        </v-col>
    </v-row>
    <WaitlistForm v-if="config.public.enableWaitlist" id="join-the-waitlist" />
</template>

<script lang="ts">
export default {
    setup() {
        definePageMeta({
            layout: 'home',
        })

        const config = useRuntimeConfig();

        return {
            config
        }
    },
}

</script>